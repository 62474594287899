export const curriculumHTML = `
<h2 style='text-align:center;'>DATOS CURRICULARES</h2>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:center;'><br></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:center;'><br></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:200%;'><span style='font-family:"Arial",sans-serif; font-weight: 600;'>Nacida en la Ciudad de Salta, Rep&uacute;blica Argentina.&nbsp;</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:200%;'><span style='font-family:"Arial",sans-serif; font-weight: 600;'>Pianista Licenciada en M&uacute;sica.</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:200%;'><span style='font-family:"Arial",sans-serif; font-weight: 600;'>Poeta.</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:200%;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:200%;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<h1 style='margin:0cm;text-align:justify;line-height:150%;font-size:16px;font-family:"Times New Roman",serif;'><span style='line-height:150%;font-family:"Arial",sans-serif;'>ESTUDIOS</span></h1>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;'>&nbsp;</p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;'>- <strong>Profesora de Piano</strong></span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;'>&nbsp; Escuela Superior de M&uacute;sica de la Provincia de Salta <em>Jos&eacute; Lo Gi&uacute;dice</em>.</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;line-height:150%;'><strong><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></strong></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;line-height:150%;'><strong><span style='font-family:"Arial",sans-serif;'>- Profesora Superior de Piano</span></strong></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;line-height:150%;'><span style='font-family:"Arial",sans-serif;'>&nbsp; Conservatorio Nacional &ldquo;CARLOS L&Oacute;PEZ BUCHARDO&rdquo;</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;margin-left:70.8pt;text-align:justify;line-height:150%;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;line-height:150%;'><strong><span style='font-family:"Arial",sans-serif;'>- Profesora Universitaria de M&uacute;sica</span></strong></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;line-height:150%;'><span style='font-family:"Arial",sans-serif;'>&nbsp; Instituto Universitario Nacional de Arte</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;line-height:150%;'><span style='font-family:"Arial",sans-serif;'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;line-height:150%;'><strong><span style='font-family:"Arial",sans-serif;'>- Licenciada en Artes Musicales</span></strong></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;'>&nbsp; Universidad Nacional del Arte.</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:200%;'><span style='font-family:"Arial",sans-serif;'>Fueron sus maestras de piano las Maestras Prof. Marina ARROZ, Salta; Prof. Zenaide LISI DE CRIVELLI, Salta; Prof. Delia SACERDOTE DE BERETERVIDE, Buenos Aires y Prof. Graciela BERETERVIDE, Buenos Aires.</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:200%;'><span style='font-family:"Lucida Console";'>&nbsp;</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;'><span style='font-family:"Lucida Console";'>&nbsp;</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;line-height:150%;'><strong><span style='font-family:"Arial",sans-serif;'>OTRAS ACTIVIDADES</span></strong></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;line-height:150%;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;line-height:150%;'><span style='font-family:"Arial",sans-serif;'>Realiz&oacute; talleres de poes&iacute;a, narrativa y oratoria.</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;line-height:150%;'><span style='font-family:"Arial",sans-serif;'>Estudi&oacute; lat&iacute;n, griego y portugu&eacute;s; actualmente toma clases de japon&eacute;s.</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;line-height:150%;'><span style='font-family:"Arial",sans-serif;'>Tom&oacute; clases de oratoria y declamaci&oacute;n con la Maestra Mar&iacute;a Ang&eacute;lica VILCHES.</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;line-height:150%;'><span style='font-family:"Arial",sans-serif;'>Estudi&oacute; poes&iacute;a haiku con la Profesora Neri MENDIARA.</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;line-height:150%;'><span style='font-family:"Arial",sans-serif;'>Actualmente toma clases de escritura con el Profesor Rolando PACIENTE.</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;line-height:150%;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;line-height:150%;'><span style='font-family:"Arial",sans-serif;'>Particip&oacute; de varias antolog&iacute;as tanto a nivel nacional como internacional en poes&iacute;a libre y en poes&iacute;a haiku.</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;line-height:150%;'><span style='font-family:"Arial",sans-serif;'>Obtuvo diferentes premios en concursos literarios tanto en Argentina como en Espa&ntilde;a.</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:150%;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;line-height:150%;'><span style='font-family:"Arial",sans-serif;'>Paralelamente incursion&oacute; en las artes marciales de Jap&oacute;n, con un recorrido de 37 a&ntilde;os en la v&iacute;a marcial, con Sensee Luis Alberto FALCONE, de Shugendo Ry&ucirc; Nippon.&nbsp;</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;line-height:150%;'><span style='font-family:"Lucida Console";'>&nbsp;</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;line-height:150%;'><span style='font-family:"Lucida Console";'>&nbsp;</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:200%;'><strong><span style='font-family:"Arial",sans-serif;'>LIBROS PUBLICADOS</span></strong></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:200%;'><span style='font-family:"Arial",sans-serif;'>- <em>Poemas e Im&aacute;genes. Copahue</em>, Editora Lyra, Bs. As., 2004.</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:200%;'><span style='font-family:"Arial",sans-serif;'>- <em>Poemas Crepusculares</em>, Ediciones Ra&iacute;z Alternativa, Bs. As., 2006.</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:200%;'><span style='font-family:"Arial",sans-serif;'>- <em>Haiku I</em>, Editorial Dunken, Bs. As., 2006.</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:200%;'><span style='font-family:"Arial",sans-serif;'>- <em>Versos Traviesos</em>, Editorial Tahiel, Bs. As., 2021.</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:200%;'><span style='font-family:"Arial",sans-serif;'>- <em>a veces la vida (poemas en min&uacute;scula)</em>, Editorial Hesp&eacute;rides, Bs. As., 2022.</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:200%;'><span style='font-family:"Arial",sans-serif;'>- <em>a veces el tiempo (poemas en min&uacute;scula)</em>, Editorial Hesp&eacute;rides, Bs. As., 2022.</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:200%;'><span style='font-family:"Arial",sans-serif;'>- <em>a veces el amor (poemas en min&uacute;scula)</em>, Editorial Hesp&eacute;rides, Bs. As., &nbsp;2022.</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:200%;'><span style='font-family:"Arial",sans-serif;'>- <em>Haiku II &ndash; Y otra vez primavera</em>, Editorial Hesp&eacute;rides, Bs. As., &nbsp;2022.</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:200%;'><span style='font-family:"Arial",sans-serif;'>- <em>Primera Ocurrencia (poemas en min&uacute;scula)</em>, Editorial Hesp&eacute;rides, Bs. As., 2022.</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:200%;'><span style='font-family:"Arial",sans-serif;'>- <em>Segunda Ocurrencia (poemas en min&uacute;scula)</em>, Editorial Hesp&eacute;rides, Bs. As., 2022.</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:200%;'><span style='font-family:"Arial",sans-serif;'>- <em>Casi Poemas, Casi Zen</em>, edici&oacute;n digital, Ciudad Aut&oacute;noma de Bs. As., 2022.</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:200%;'><span style='font-family:"Arial",sans-serif;'>- <em>A Alfonsina &ndash; Poemas de Fuego y Mar</em>, edici&oacute;n digital, Ciudad Aut&oacute;noma de Bs. As., 2022.</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:200%;'><span style='font-family:"Arial",sans-serif;'>- <em>Poemas del Dejarme Ir</em>, edici&oacute;n digital, Ciudad Aut&oacute;noma de Bs. As., 2022.</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:200%;'><span style='font-family:"Arial",sans-serif;'>- <em>Poemas Cortos de Instantes Largos</em>, edici&oacute;n digital, Ciudad Aut&oacute;noma de &nbsp; Bs. &nbsp; &nbsp; As., 2022.</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:200%;'><span style='font-family:"Arial",sans-serif;'>- <em>Poes&iacute;a Murmurada,&nbsp;</em>edici&oacute;n digital, Ciudad Aut&oacute;noma de Bs. As., 2023.</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:200%;'><span style='font-family:"Arial",sans-serif;'>- <em>Zen</em>, edici&oacute;n digital, Ciudad Aut&oacute;noma de Bs. As., 2023.</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:200%;'><em><span style='font-family:"Arial",sans-serif;'>- Ac&aacute; tambi&eacute;n los fantasmas de Jacobo R</span></em><span style='font-family:"Arial",sans-serif;'>, edici&oacute;n digital, Ciudad Aut&oacute;noma de Bs. As., 2023.</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:200%;'><span style='font-family:"Arial",sans-serif;'>- <em>Las Ventanas de Juarroz</em>, edici&oacute;n digital, Ciudad Aut&oacute;noma de Bs. As., 2023.</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:200%;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:200%;'><strong><span style='font-family:"Arial",sans-serif;'>POES&Iacute;A LIBRE</span></strong></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:150%;'><span style='font-family:"Arial",sans-serif;'>INTERVIENE EN LAS SIGUIENTES ANTOLOG&Iacute;AS DE POES&Iacute;A LIBRE</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:200%;'><strong><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></strong></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:200%;'><em><span style='font-family:"Arial",sans-serif;'>- Autores Secretos</span></em><span style='font-family:"Arial",sans-serif;'>. Editorial Clatvesa, Bs. As., 2004.</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:200%;'><em><span style='font-family:"Arial",sans-serif;'>- La Gran Apuesta</span></em><span style='font-family:"Arial",sans-serif;'>&nbsp;compilado por Fernando S&aacute;nchez Zinny, Editorial Dunken, Bs. As., 2005.</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:200%;'><span style='font-family:"Arial",sans-serif;'>- <em>XXV Antolog&iacute;a Literaria Argentina en</em><em>&nbsp;Versos y Prosas</em>, Ediciones Ra&iacute;z Alternativa, Bs. As., 2005.</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:200%;'><em><span style='font-family:"Arial",sans-serif;'>- La Nueva Literatura de Habla Hispana</span></em><span style='font-family:"Arial",sans-serif;'>, Editorial Nuevo Ser, Bs. As., 2005.</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:200%;'><em><span style='font-family:"Arial",sans-serif;'>- Silencios de Hielo y Papel</span></em><span style='font-family:"Arial",sans-serif;'>, Editorial Abaco, Espa&ntilde;a, 2005.</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:200%;'><em><span style='font-family:"Arial",sans-serif;'>- Latinoam&eacute;rica escribe</span></em><span style='font-family:"Arial",sans-serif;'>, Ediciones Ra&iacute;z Alternativa, Bs. As., 2006.</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:200%;'><span style='font-family:"Arial",sans-serif;'>- <em>XXXII Antolog&iacute;a de Poes&iacute;a y Narrativa breve</em> <em>Escritura sin Fronteras</em>, Ediciones Ra&iacute;z Alternativa, Bs. As., 2007.&nbsp;</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:200%;'><span style='font-family:"Arial",sans-serif;'>- <em>Antolog&iacute;a V Certamen de poes&iacute;a y prosa</em> <em>Las Lagunas - Ars Creatio</em>, Torrevieja, Andorra, Espa&ntilde;a, 2008.</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:200%;'><em><span style='font-family:"Arial",sans-serif;'>- Huellas del Alma</span></em><span style='font-family:"Arial",sans-serif;'>, Editorial Dunken, Bs. As., 2009.</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:200%;'><em><span style='font-family:"Arial",sans-serif;'>- Bit&aacute;cora</span></em><span style='font-family:"Arial",sans-serif;'>, Editorial Dunken, Bs. As., 2010.</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:200%;'><span style='font-family:"Arial",sans-serif;'>- <em>XLI Antolog&iacute;a de Poes&iacute;a y Narrativa breve</em> <em>Escritura sin Frontera</em>, Ediciones Ra&iacute;z Alternativa, Bs. As., 2010.</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:200%;'><em><span style='font-family:"Arial",sans-serif;'>- Calidoscopio de voces</span></em><span style='font-family:"Arial",sans-serif;'>, compilado por Marita Rodr&iacute;guez-Cazaux, Editorial Dunken, Bs. As., 2012.</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:200%;'><em><span style='font-family:"Arial",sans-serif;'>- Ojos de Semilla</span></em><span style='font-family:"Arial",sans-serif;'>, compilado por Carla Demark, Editorial Dunken, Bs. As., 2015.</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:200%;'><em><span style='font-family:"Arial",sans-serif;'>- Poes&iacute;a Desplegada</span></em><span style='font-family:"Arial",sans-serif;'>, compilado por Marita Rodr&iacute;guez-Cazaux. &nbsp;Editorial Dunken, Bs. As., 2019.</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:200%;'><em><span style='font-family:"Arial",sans-serif;'>- Rimas Personales</span></em><span style='font-family:"Arial",sans-serif;'>, compilado por Mercedes Baigorri. &nbsp;Editorial Dunken, Bs. As., 2020.</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:200%;'><em><span style='font-family:"Arial",sans-serif;'>- Antolog&iacute;a del Taller de Escritura Creativa</span></em><span style='font-family:"Arial",sans-serif;'>, coordinado por Prof. Rolando Paciente, Editorial Tahiel, Bs. As., 2020.</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:200%;'><em><span style='font-family:"Arial",sans-serif;'>- Antolog&iacute;a del Taller de Escritura Creativa</span></em><span style='font-family:"Arial",sans-serif;'>, coordinado por Prof. Rolando Paciente, Editorial Tahiel, Bs. As., 2021.</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:200%;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:150%;'><span style='font-family:"Arial",sans-serif;'>PREMIOS Y MENCIONES EN POES&Iacute;A LIBRE</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:150%;'><em><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></em></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:150%;'><span style='font-family:"Arial",sans-serif;'>- 2do premio. Concurso <em>Autores Secretos</em>, Editorial Clatvesa, Bs. As., 2004.</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:150%;'><br></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:150%;'><span style='font-family:"Arial",sans-serif;'>-&nbsp;</span><span style='font-family:"Arial",sans-serif;'>Medalla y menci&oacute;n especial. Concurso Nacional de Poes&iacute;a Latinoamericana <em>Voces&nbsp;</em>- 2005.</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:150%;'><br></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:150%;'><span style='font-family:"Arial",sans-serif;'>- Menci&oacute;n y Medalla. Ediciones Ra&iacute;z Alternativa - <em>XXV Antolog&iacute;a Literaria Argentina en&nbsp;</em><em>Versos y Prosas</em>, Bs. As., 2005.</span><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:150%;'><br></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;'>- Finalista en el IX Certamen Literario Internacional de Poes&iacute;a y Narrativa, Editorial Nuevo Ser, Bs. As., 2005.</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;margin-left:36.0pt;line-height:150%;'><br></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:150%;'><span style='font-family:"Arial",sans-serif;'>- &nbsp;Finalista. &nbsp;<em>Antolog&iacute;a</em> <em>La Gran Apuesta</em> compilado por Fernando S&aacute;nchez Zinny. Editorial Dunken, Bs. As., 2005.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:35.4pt;font-size:16px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:150%;'><span style='font-family:"Arial",sans-serif;'>- Menci&oacute;n Especial. Concurso Latinoamericano de Poes&iacute;a y Cuento <em>Contraluz</em>. Editorial Cathedra, Bs. As., 2005.</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:150%;'><br></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:150%;'><span style='font-family:"Arial",sans-serif;'>- Finalista en XIII Certamen Internacional de Poes&iacute;a y Cuento. Editorial Letras Vivas, 2005.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:35.4pt;font-size:16px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:150%;'><span style='font-family:"Arial",sans-serif;'>- Primer Premio. Concurso <em>Latinoam&eacute;rica Escribe 2005</em>, Ediciones Ra&iacute;z Alternativa, Bs. As., 2005.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:35.4pt;font-size:16px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:150%;'><span style='font-family:"Arial",sans-serif;'>- Finalista. Integra la <em>XXVII Antolog&iacute;a de Poes&iacute;a y narrativa breve</em> <em>Latinoam&eacute;rica Escribe</em>, Bs. As., 2006.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:35.4pt;font-size:16px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:150%;'><span style='font-family:"Arial",sans-serif;'>- Finalista en II Certamen de Poes&iacute;a. Integra la <em>Antolog&iacute;a</em> <em>Silencios de Hielo y Papel</em>, Editorial Abaco, Espa&ntilde;a, 2006.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:35.4pt;font-size:16px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:150%;'><span style='font-family:"Arial",sans-serif;'>- Finalista en XIV Concurso de Cuento y Poes&iacute;a <em>Leopoldo Marechal</em>, Municipio de Mor&oacute;n, Bs. As., 2007.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:35.4pt;font-size:16px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:150%;'><span style='font-family:"Arial",sans-serif;'>- Menci&oacute;n Especial. VII Concurso Literario Internacional <em>Dr. Santiago Antonio Vera</em>, La Calera, C&oacute;rdoba, 2007.</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;margin-left:36.0pt;line-height:150%;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:150%;'><span style='font-family:"Arial",sans-serif;'>- Menci&oacute;n de Honor. Concurso Interamericano de Poes&iacute;a 2007<em>&nbsp;Fundaci&oacute;n Avon para la Mujer</em>, Bs. As., 2007.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:35.4pt;font-size:16px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:150%;'><span style='font-family:"Arial",sans-serif;'>- Primera Menci&oacute;n de Honor. Concurso Literario Internacional <em>Letras al Viento</em>, Bah&iacute;a Blanca, Bs. As., 2007.</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;margin-left:36.0pt;line-height:150%;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:150%;'><span style='font-family:"Arial",sans-serif;'>- Finalista. Integra la XXXII <em>Antolog&iacute;a de Poes&iacute;a y Narrativa breve</em> <em>Escritura sin Frontera</em>, Ediciones Ra&iacute;z Alternativa, Bs. As., 2007.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:35.4pt;font-size:16px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:150%;'><span style='font-family:"Arial",sans-serif;'>- Finalista, <em>Club Abuelos</em>. Espa&ntilde;a.2008.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:35.4pt;font-size:16px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:150%;'><span style='font-family:"Arial",sans-serif;'>- Finalista. Concurso V Certamen<em>&nbsp;Ars Creatio - Las Lagunas</em> - Ayuntamiento de Torre Vieja, Espa&ntilde;a, 2008.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:35.4pt;font-size:16px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:150%;'><span style='font-family:"Arial",sans-serif;'>- Finalista. Integra <em>Antolog&iacute;a</em> <em>Huellas del Alma</em> de Editorial Dunken, 2010.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:35.4pt;font-size:16px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:150%;'><span style='font-family:"Arial",sans-serif;'>- Finalista. Participa en <em>Antolog&iacute;a</em> <em>Bit&aacute;cora</em>, Editorial Dunken, 2010.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:35.4pt;font-size:16px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:150%;'><span style='font-family:"Arial",sans-serif;'>- Finalista <em>1</em>&deg; Certamen Internacional de Poes&iacute;a Ediciones Literarte, Bs. As., 2010.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:35.4pt;font-size:16px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:150%;'><span style='font-family:"Arial",sans-serif;'>- Finalista. Integra <em>XLI Antolog&iacute;a de Poes&iacute;a y Narrativa breve</em> <em>Escritura sin Frontera</em>, Ediciones Ra&iacute;z Alternativa, Bs. As., 2010.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:35.4pt;font-size:16px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:150%;'><span style='font-family:"Arial",sans-serif;'>- Finalista. Integra la <em>Selecci&oacute;n de Cuento y Poes&iacute;a</em> <em>Calidoscopio de voces</em>, Editorial Dunken, Bs. As., 2012.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:35.4pt;font-size:16px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:150%;'><span style='font-family:"Arial",sans-serif;'>- Finalista. Integra la <em>Antolog&iacute;a</em> <em>Ojos de Semilla</em> compilado y corregido por Carla Demark, Editorial Dunken, Bs. As., 2015.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:35.4pt;font-size:16px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:150%;'><span style='font-family:"Arial",sans-serif;'>- 15&deg; Menci&oacute;n General. &nbsp;Certamen Internacional de <em>Poes&iacute;a en honor al vino</em>, Quequ&eacute;n, Bs. As., 2015.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:35.4pt;font-size:16px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:150%;'><span style='font-family:"Arial",sans-serif;'>- 2do premio. Fundaci&oacute;n <em>Huellas Caribe&ntilde;as</em>, Samarlee Revista Literaria, Colombia, 2016.</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:150%;'><span style='font-family:"Arial",sans-serif;'>- Finalista. Concurso <em>Poes&iacute;a Desplegada</em>, compilado y corregido por Marita Rodr&iacute;guez Cazaux, Editorial Dunken, Bs. As., 2019.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:35.4pt;font-size:16px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:150%;'><span style='font-family:"Arial",sans-serif;'>- Finalista. Concurso Editorial Dunken. Integra <em>Antolog&iacute;a</em> <em>Rimas Personales</em>, compilado por Mercedes Baigorri. Marzo de 2020.</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:150%;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'><strong><span style='font-family:"Arial",sans-serif;'>POESIA HAIKU</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'><br></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;'>INTERVIENE EN LAS SIGUIENTES ANTOLOG&Iacute;AS DE POES&Iacute;A HAIKU</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:200%;'><em><span style='font-family:"Arial",sans-serif;'>- Un viejo Estanque</span></em><span style='font-family:"Arial",sans-serif;'>, antolog&iacute;a de haiku contempor&aacute;neo en Espa&ntilde;a, Granada, Editorial Comares, 2013.</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:200%;'><em><span style='font-family:"Arial",sans-serif;'>- Haikus VI</span></em><span style='font-family:"Arial",sans-serif;'>, integra esta antolog&iacute;a como finalista del I Certamen de Haiku <em>Mario Benedetti</em>, Letras como Espada, Toledo, Espa&ntilde;a, 2016.</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:200%;'><em><span style='font-family:"Arial",sans-serif;'>- Haikus VIII</span></em><span style='font-family:"Arial",sans-serif;'>, integra esta antolog&iacute;a como finalista del I Certamen de Haiku <em>Jorge Luis Borges</em>, Letras como Espada, Toledo, Espa&ntilde;a, 2016.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:35.4pt;font-size:16px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:200%;'><span style='font-family:"Arial",sans-serif;'>- Integra <em>El Libro de Los Talleres XXXI,</em> Taller de Haiku dirigido por Prof. Rolando Paciente, Editorial Dunken, Bs. As., 2017.</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:200%;'><span style='font-family:"Arial",sans-serif;'>- Integra <em>El libro de los Talleres XXXIII</em>, Taller de Haiku dirigido por Prof. Rolando Paciente. Editorial Dunken, 2019.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:35.4pt;font-size:16px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:200%;'><em><span style='font-family:"Arial",sans-serif;'>-Flor en la Nieve</span></em><span style='font-family:"Arial",sans-serif;'>, integra esta antolog&iacute;a como finalista del VI Concurso de Haiku <em>Cosas peque&ntilde;as</em>, Mundo Escritura, Espa&ntilde;a, 2020.</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:200%;'><em><span style='font-family:"Arial",sans-serif;'>- Faro</span></em><span style='font-family:"Arial",sans-serif;'>, integra esta antolog&iacute;a como finalista del VII Concurso de Haiku <em>Por una sonrisa, un cielo</em>, Mundo Escritura, Espa&ntilde;a, 2021.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:35.4pt;font-size:16px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:200%;'><em><span style='font-family:"Arial",sans-serif;'>-Haikus y Sonetos LV</span></em><span style='font-family:"Arial",sans-serif;'>, integra esta antolog&iacute;a como finalista del VII Certamen de haiku <em>Ueshima Onitsura</em>, Letras como Espada, Toledo, Espa&ntilde;a, 2021.</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:200%;'><span style='font-family:"Arial",sans-serif;'>- <em>Haikus y Sonetos LVII</em>, integra esta antolog&iacute;a como finalista del VI Certamen de Haiku <em>Hawai Chigetsu</em>, Letras como Espada, Toledo, Espa&ntilde;a, 2021.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:35.4pt;font-size:16px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:200%;'><span style='font-family:"Arial",sans-serif;'>- <em>Compromiso</em>, integra esta antolog&iacute;a como finalista del Concurso de Haiku <em>Homenaje a las Mujeres</em>, Mundo Escritura, Espa&ntilde;a, 2021.</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:200%;'><span style='font-family:"Arial",sans-serif;'>- <em>Hojas de un libro</em>, integra esta antolog&iacute;a como finalista del Concurso de Haiku <em>Un libro, una vida</em>, Mundo Escritura, Espa&ntilde;a, 2021.</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:200%;'><span style='font-family:"Arial",sans-serif;'>- <em>Haikus y Sonetos LXX</em>, como finalista del VII Certamen de Haiku <em>Mario Benedetti</em>, Letras como Espada, Toledo, Espa&ntilde;a, 2022.</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:200%;'><span style='font-family:"Arial",sans-serif;'>- <em>Haiku&nbsp;</em>del Taller de Escritura Creativa, coordinado por el Prof. Rolando Paciente, Editorial Hesp&eacute;rides, Bs. As., 2022.</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;margin-left:36.0pt;line-height:200%;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:150%;'><span style='font-family:"Arial",sans-serif;'>PREMIOS Y MENCIONES EN POES&Iacute;A HAIKU</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;margin-left:36.0pt;line-height:150%;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:150%;'><span style='font-family:"Arial",sans-serif;'>- 2do Premio. Concurso Instituto Tōzai - III Encuentro Internacional de Haiku, octubre de 2004.</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:150%;'><span style='font-family:"Arial",sans-serif;'>- Menci&oacute;n. Concurso Instituto Tōzai - III Encuentro Internacional de Haiku, octubre de 2004.</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:150%;'><span style='font-family:"Arial",sans-serif;'>- 1er premio - IV Encuentro Internacional de Haiku- Instituto Tōzai, 2006.</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:150%;'><span style='font-family:"Arial",sans-serif;'>- 3er. Premio en Concurso Haiku No Michi. Espa&ntilde;a, 2007.</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:150%;'><span style='font-family:"Arial",sans-serif;'>- 1er. Premio Concurso Instituto Tōzai - V Encuentro Internacional de Haiku, Bs. As., 2008.</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:150%;'><span style='font-family:"Arial",sans-serif;'>- 1er Premio (compartido) - Concurso Instituto Tōzai. Bs. As., 2010.</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:200%;'><span style='font-family:"Arial",sans-serif;'>- Finalista del I Certamen de Haiku <em>Mario Benedetti</em>, Letras como Espada, Toledo, Espa&ntilde;a, 2016.</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:200%;'><span style='font-family:"Arial",sans-serif;'>- Finalista del I Certamen de Haiku <em>Jorge Luis Borges</em>, Letras como Espada, Toledo, Espa&ntilde;a, 2016.</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:200%;'><span style='font-family:"Arial",sans-serif;'>- Finalista del VI Concurso de Haiku <em>Cosas peque&ntilde;as</em>, Mundo Escritura, Espa&ntilde;a, 2020.</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:200%;'><span style='font-family:"Arial",sans-serif;'>- Finalista del VII Concurso de Haiku <em>Por una sonrisa, un cielo</em>, Mundo Escritura, Espa&ntilde;a, 2021.</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:200%;'><span style='font-family:"Arial",sans-serif;'>- Finalista del VII Certamen de Haiku <em>Ueshima Onitsura</em>, Letras como Espada, Toledo, Espa&ntilde;a, 2021.</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:200%;'><span style='font-family:"Arial",sans-serif;'>- Finalista del VI Certamen de Haiku <em>Hawai Chigetsu</em>, Letras como Espada, Toledo, Espa&ntilde;a, 2021.</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:200%;'><span style='font-family:"Arial",sans-serif;'>- Finalista del Concurso de Haiku <em>Homenaje a las Mujeres</em>, Mundo Escritura, Espa&ntilde;a, 2021.</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:150%;'><span style='font-family:"Arial",sans-serif;'>- Finalista del Concurso de Haiku <em>Un libro, una vida</em>, Mundo Escritura, Espa&ntilde;a, 2021.</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;line-height:150%;'><span style='font-family:"Arial",sans-serif;'>- Finalista del VII Certamen de Haiku <em>Mario Benedetti</em>, Letras como Espada, Toledo, Espa&ntilde;a, 2022.</span></p>`;
